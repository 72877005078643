.intro-overlay {
  .top {
    height: 100vh;
    position: absolute;
    width: 100%;

    z-index: 100;
    .overlay-top {
      position: absolute;
      height: 100%;
      width: 33.33vw;

      background: linear-gradient(#e51b23, #090101);
      left: 0;
      right: 0;
      bottom: 0;

      @media screen and (max-width: 600px) {
        width: 100vw;
      }

      &:nth-child(2) {
        left: 33.33%;

        @media screen and (max-width: 600px) {
          display: none;
        }
      }
      &:nth-child(3) {
        left: 66.66%;
        @media screen and (max-width: 600px) {
          display: none;
        }
      }
    }
  }
}
